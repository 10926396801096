<template>
  <a
    v-if="link"
    :href="linkForTemplate"
    v-bind="relAttr"
    :target="targetWindow"
    :title="title"
    :aria-label="ariaLabel"
    v-on="listeners"
    v-click="onClick"
  >
    <slot />
  </a>
  <span v-else v-on="$listeners">
    <slot />
  </span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { equals, pick } from 'ramda'
import { PROP_TYPES, propValidator } from '@/utils/validators'
import {
  compareRoutes,
  checkIfUrlStartsWithDomain,
  removeDomainFromUrl,
  isString
} from '@fmpedia/helpers'
import mixins from '@/utils/mixins'
import { TARGET } from 'enums/links'
import { decodeHTML } from 'entities'
import { LinkRelAttributeConverter } from '@fmpedia/html-tools'
import {
  BASE_HTML_REL_ATTRIBUTE_VALUES,
  HTML_REL_ATTRIBUTE_VALUE
} from '@fmpedia/enums'
import { HEADER_SCROLL_OFFSET } from 'enums/header-scroll-offset'
import { normalizeUrlSlash, normalizeRoutePath } from '@/plugins/helper'

const SCROLL_TO_DELAY = 500
const PREVIEW_HEADER_OFFSET = 80

export default {
  name: 'ALink',
  mixins: [mixins.urlGenerators],
  props: {
    directive: propValidator([PROP_TYPES.STRING], false, null),
    removeDirective: propValidator([PROP_TYPES.BOOLEAN], false, false),
    openInNewTab: propValidator(
      [PROP_TYPES.BOOLEAN, PROP_TYPES.STRING],
      false,
      null
    ),
    to: propValidator([PROP_TYPES.STRING, PROP_TYPES.OBJECT], false),
    trailingSlash: propValidator([PROP_TYPES.BOOLEAN], false, true),
    // /**
    //  * This prop is used for router-link to use "name" property instead of "path"
    //  * In case we received wrong path from backend usually we will be redirected to 404 error page,
    //  * but if first part of path is the same as existing parent route we will be redirected to
    //  * dynamic route _category.vue as dynamic path can be any path. But in such case code on
    //  * _category page will not be executed and we will be redirected to all-news empty page.
    //  */
    // routeByName: {
    //   type: Boolean,
    //   default: false
    // },
    title: propValidator([PROP_TYPES.STRING], false),
    preventDefault: propValidator([PROP_TYPES.BOOLEAN], false),
    ariaLabel: propValidator([PROP_TYPES.STRING], false)
  },
  computed: {
    ...mapGetters({
      isPreviewMode: 'isPreviewMode'
    }),
    listeners() {
      const { click, ...listeners } = this.$listeners

      return listeners
    },
    link() {
      if (!this.to) return null

      if (this.isInnerLink) {
        return this.generateInternalLink(this.to)
      } else if (this.checkIfFmOrFmDirLink(this.to)) {
        return normalizeUrlSlash(this.to, true)
      } else {
        /**
         * We decode HTML entities here. More details in this bug:
         * https://adraba.atlassian.net/browse/FMP-15952
         */
        return decodeHTML(this.to)
      }
    },
    linkForTemplate() {
      if (!this.link) return null

      if (isString(this.link)) return this.link

      return this.link.fullPath
    },
    isInnerLink() {
      return this.to ? this.checkIfInnerLink(this.to) : true
    },
    openInNewTabBoolean() {
      try {
        return JSON.parse(this.openInNewTab)
      } catch {
        return null
      }
    },
    targetWindow() {
      if (this.openInNewTabBoolean != null) {
        return this.openInNewTabBoolean ? TARGET.BLANK : TARGET.SELF
      }

      /**
       * The default value for external links should be "open in new tab"
       */
      return this.isInnerLink || this.checkIfFmOrFmDirLink(this.to)
        ? TARGET.SELF
        : TARGET.BLANK
    },
    relAttr() {
      return this.removeDirective || !this.relProps
        ? {}
        : {
            rel: this.relProps
          }
    },
    relProps() {
      const {
        htmlRelString,
        htmlRelStringWithBaseDirectives
      } = new LinkRelAttributeConverter(this.directive)

      if (this.directive != null) {
        return this.isInnerLink
          ? htmlRelString
          : htmlRelStringWithBaseDirectives
      }

      /**
       * The default value for external links should be "nofollow"
       */
      return this.isInnerLink || this.checkIfFmOrFmDirLink(this.to)
        ? ''
        : LinkRelAttributeConverter.generateRelString([
            HTML_REL_ATTRIBUTE_VALUE.NOFOLLOW,
            ...BASE_HTML_REL_ATTRIBUTE_VALUES
          ])
    }
  },
  methods: {
    ...mapActions({
      concurrentRouterPush: 'router/concurrentRouterPush'
    }),
    async onClick(event) {
      const newRoute = isString(this.link)
        ? this.$router.resolve(
            removeDomainFromUrl(this.link, this.$env.DOMAIN_URL)
          ).resolved
        : this.link
      const { params, query, name, hash: toHash, path, fullPath } = newRoute
      const handledHash = this.$helper.removeEndingSlashes(toHash)

      const isLinkWithHashAndSameRoutePath =
        handledHash && this.checkIfTheSameRoutePath(path)

      /**
       * In preview mode we don't allow editors to navigate between pages
       */
      if (this.isPreviewMode) {
        event.preventDefault()

        /**
         * The default link behavior here will lead to infinite progress bar
         * running in the preview mode. That's why, we scroll using $scrollTo.
         */
        if (isLinkWithHashAndSameRoutePath) {
          this.$scrollTo(handledHash, SCROLL_TO_DELAY, {
            offset: HEADER_SCROLL_OFFSET - PREVIEW_HEADER_OFFSET
          })
          return
        }
      }

      this.$emit('click', event)

      if (this.preventDefault) {
        event.preventDefault()
        return
      }

      if (!this.isInnerLink || this.openInNewTabBoolean) return

      const fromHash = this.$route.hash

      const isTheSameRoute =
        this.checkIfTheSameRoute(name, query, params) ||
        this.checkIfFromPaginatedRouteToTheSame(newRoute)

      if (process.client && isTheSameRoute && !toHash && !fromHash) {
        window.location.reload()
      }

      if (this.$helper.isUrlWithPagination() && name === this.$route.name) {
        window.location.href = this.link
      } else if (!isLinkWithHashAndSameRoutePath) {
        await this.followRouterLink({ path, params, query, handledHash })
      }

      if (isTheSameRoute && !handledHash) {
        this.$scrollTo('body', SCROLL_TO_DELAY)
      }

      if (handledHash) {
        this.$nextTick(() => {
          /**
           * router.push or router.replace would scroll the page up and show
           * a progress bar, which is not expected in such case
           */
          window.history.replaceState(null, '', fullPath)

          this.$scrollTo(handledHash, SCROLL_TO_DELAY, {
            offset: HEADER_SCROLL_OFFSET
          })
        })
      }

      event.preventDefault()
    },
    async followRouterLink({ path, params, query, handledHash }) {
      const payload = {
        router: this.$router,
        to: {
          path,
          params,
          query,
          hash: handledHash
        }
      }
      await this.concurrentRouterPush(payload)
    },
    checkIfTheSameRoutePath(path) {
      return this.$route.path === path
    },
    checkIfTheSameRoute(name, query, params) {
      const currentRoute = pick(['name', 'query', 'params'], this.$route)
      return equals({ name, query, params }, currentRoute)
    },
    checkIfFromPaginatedRouteToTheSame(newRoute) {
      const {
        isParentRouteFullPathChanged,
        isFromPaginatedToParent
      } = compareRoutes(this.$route, newRoute)

      return !isParentRouteFullPathChanged && isFromPaginatedToParent
    },
    checkIfInnerLink(url) {
      if (typeof url !== 'string') return true

      return (
        checkIfUrlStartsWithDomain(url, this.$env.DOMAIN_URL) ||
        (!this.checkIfHasProtocol(url) && !this.checkIfEmail(url))
      )
    },
    checkIfFmOrFmDirLink(url) {
      if (typeof url !== 'string') return false

      return (
        checkIfUrlStartsWithDomain(url, this.$env.FM_DOMAIN_URL) ||
        checkIfUrlStartsWithDomain(url, this.$env.FM_DIR_DOMAIN_URL)
      )
    },
    checkIfHasProtocol(url) {
      return /https?:\/\//.test(url)
    },
    checkIfEmail(url) {
      return /mailto:/.test(url)
    },
    generateInternalLink(link) {
      const navigateTo = isString(link)
        ? normalizeUrlSlash(link, this.trailingSlash)
        : normalizeRoutePath(
            this.$router.resolve(link).resolved,
            this.trailingSlash
          )

      return navigateTo
    }
  }
}
</script>
